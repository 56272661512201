import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import config from '../../data/SiteConfig';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../css/main.scss';
import { Container } from '@material-ui/core';
import MarkdownSeo from '../components/SEO/MarkdownSEO';

export default function CareersTemplate({ data }) {
  const careerNode = data.markdownRemark;
  const career = careerNode.frontmatter;
  const image = getImage(career.featuredImage.childImageSharp);
  const url = '/careers';

  return (
    <Layout>
      <Container>
        <Helmet>
          <title>{`${career.title} | ${config.siteTitleShort}`}</title>
        </Helmet>
        <MarkdownSeo postNode={careerNode} url={url} />
        <div className="legacy">
          <h1 className="title">{career.title}</h1>
          <GatsbyImage image={image} alt={career.title} />
          <div dangerouslySetInnerHTML={{ __html: careerNode.html }} />
        </div>
      </Container>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CarrerBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
